.bottomSheetOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* background blur effect */
  z-index: 1000;
  transition: transform 300ms cubic-bezier(1, 0, 0, 1);
  transform: translateY(150%);
}

.showSheet {
  transform: translateY(0);
}

.bottomSheet {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* transform: translateY(0);
  transition: transform 0.3s ease-in-out; */
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  min-height: 400px;
  max-height: 100%;
}

.bottomSheetHeader, .bottomSheetLongHeader {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 20px 0;
}

.bottomSheetTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #252527;
}

.bottomSheetLongHeader{
  height: 88px;
}

.bottomSheetBody {
  flex: 1;
  overflow-y: auto;
  padding: 0px 20px 12px;
}

.bottomSheetBottom {
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px;
  margin: 0 20px;
}

.bottomSheetBottom > * {
  flex: 1;
}

.backButton,
.closeButton,
.resizeButton {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.resizeButton {
  width: 40px;
  height: 4px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  left: calc(50vw - 20px);
  top: -10px;
}
