$defaultIconPrimary: #00B4E5;
$defaultProgressPrimaryColor: #8cbb10;
$defaultProgressPrimaryColorBg: #c3ff1d33;

body {
    background-color: #f7f7f7 !important;
}

.bg-offwhite {
    background-color: #f7f7f7;
}

.cm-bg-primary {
    background-color: var(--default-icon-primary, $defaultIconPrimary);
}

.mob-service {
    height: 250px;
}

.cm-text-dark {
    color: #3F4254
}

.dashboard-card {
    background-color: #F3F0FF;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.dashboard-card-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #717171;
}

.dashboard-card-price {
    margin-top: 3px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #252527;
}

.cm-text-secondary {
    color: #797979;
}

.cm-text-teritiary{
    color: #717171;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-20 {
    font-size: 20px;
}

.font-700 {
    font-weight: 700;
}

.font-600 {
    font-weight: 600;
}

.font-500 {
    font-weight: 500;
}

.widget-alert-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.37);
    backdrop-filter: blur(4px);
    z-index: 999;
}

.alert-container {
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px #00000014;
    border-radius: 16px;
    padding: 24px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10%;
}

.alert-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
}

.alert-sub-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.cm-btn-widgetAlert {
    color: #FFFFFF !important;
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    border: none !important;
    border-radius: 400px !important;
    width: 100% !important;
    height: 48px !important;
}

.cm-datetime > input {
    border: none !important;
    background-color: #f7f7f7 !important;
    padding: 0 12px !important;
}

.cm-shadow-04240 {
    box-shadow: 0px 0px 4px 0px #00000010;
}

.rounded-8px {
    border-radius: 8px;
}

.-rotate-90 {
    transform: rotate(-90deg);
}

.rotate-0 {
    transform: rotate(0deg);
}

.transtion {
    transition: all 300ms ease-in-out;
}

.service {
    min-height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
}

.label.label-time-slot {
    cursor: pointer !important;
    color: #3F4254;
    width: auto !important;
    height: 40px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 0.5px #F4F4F4;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px;
    text-align: center;
    box-shadow: 0px 0px 4px 0px #00000025;
}

.label.label-time-slot.time-slot-active,
.time-slot-active {
    color: #FFFFFF;
    background-color: var(--default-icon-primary, $defaultIconPrimary);
}

.other-timeslot {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
    background-color: transparent;
    font-weight: 600;
    height: 40px;
    padding: 10px 0;
}

.other-timeslot:hover {
    color: var(--default-icon-primary, $defaultIconPrimary);
    text-decoration: underline !important;
}

.cm-booking-service-text,
.cm-booking-service-desc {
    font-size: 16px;
    line-height: normal;
    color: #fff;
    text-shadow: 1px 1px black;
    margin-bottom: 0;
}

.cm-booking-service-title {
    font-size: 32px;
    line-height: normal;
    color: #fff;
    text-shadow: 1px 1px black;
    margin-bottom: 0;
}

.cm-serviceCategory-active {
    border: 2px solid #1BC5BD;
}

.cm-btn-color {
    color: #FFFFFF !important;
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    min-width: 90px;
    border-radius: 4px !important;
}

.cm-btn-outline-primary {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
    background-color: transparent !important;
    border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    min-width: 90px;
}

.cm-btn-outline-primary:hover {
    color: #ffffff !important;
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.cm-btn-outline-green:enabled {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
    background-color: transparent !important;
    border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    min-width: 90px;
    border-radius: 4px !important;
}

.cm-btn-outline-green:not([disabled]):hover {
    color: #ffffff !important;
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.cm-btn-outline-green:disabled{
    color: #3F4254;
    background-color: #dadce3;
    border-color: #dadce3;
}

.cm-btn-green {
    color: #ffffff !important;
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    min-width: 90px;
    border-radius: 4px !important;
}

.cm-btn-green:not([disabled]):hover {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
    background-color: #ffffff !important;
    border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.cm-btn-purple {
    color: #ffffff !important;
    background-color: #4F557A !important;
    border-color: #4F557A !important;
    min-width: 90px;
    border-radius: 2px !important;
}

.cm-btn-green-outline {
    color: #8cbb10 !important;
    background-color: #ffffff !important;
    border-color: #dadada !important;
    min-width: 90px;
    border-radius: 4px !important;
}

.cm-btn-tertiary {
    color: #ffffff !important;
    background-color: #a4abb3 !important;
    border-color: #a4abb3 !important;
    min-width: 90px;
    border-radius: 4px !important;
}

.cm-btn-link {
    color: #3F4254 !important;
    background-color: transparent !important;
    border-color: none !important;
    min-width: 90px;
    font-weight: 900 !important;
    font-size: 14px !important;
}

.cm-progressBar-color>div {
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}


.cm-question-text > * {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
}

.cm-question-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
}

.qContainer {
    padding: 10px 0;
}

.cm-coupan-btn {
    background-color: #FFFFFF !important;
    color: black !important;
    border: #FFFFFF !important;
}

.cm-check-icon {
    color: #3F4254 !important;
    font-size: 20px;
}

.stepper {
    padding: 4vh 18vw !important;
}

.stepper #RFS-StepContainer {
    padding: 0;
}

.stepper #RFS-ConnectorContainer {
    top: calc((3em - 6px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
}

.stepper #RFS-Connector {
    border-top-width: 6px;
    border-color: #767676;
}

.stepper #RFS-StepButton {
    cursor: default !important;
    background: #767676;
}

.stepper #RFS-StepButton.active {
    background: var(--default-icon-primary, $defaultIconPrimary);
    z-index: 1;
}

.stepper #RFS-StepButton span {
    background: #767676;
    color: #fff;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    border: 2px solid #fff;
}

.stepper #RFS-StepButton.completed .completed {
    position: relative;
    background: #00b4e5; //var(--default-icon-primary, $defaultIconPrimary);
    color: #00b4e5; //var(--default-icon-primary, $defaultIconPrimary);
    user-select: none;
}

.stepper #RFS-StepButton.completed .completed:before {
    content: '';
    position: absolute;
    left: 45%;
    top: 6px;
    height: 14px;
    width: 6px;
    transform: rotate(45deg) translateX(-45%);
    border-bottom: 2px solid white;
    border-right: 2px solid white;
}

.container.cm-px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.cm-title-service {
    font-size: 11px;
    line-height: normal;
    font-weight: 700;
    word-wrap: break-word;
}

.StepButton-d12-0-3-28.completed {
    background-color: #0e9ec1;
}

@media (max-width: 767px) {
    .cm-consent-img.img-fluid {
        max-width: 75%;
    }

    .cm-sticky-btn {
        position: sticky;
        bottom: 0;
        background-color: #FFFFFF;
    }
}

input[type="radio"] {
    padding: 0.5em;
    -webkit-appearance: none;
    height: 13px;
    width: 13px;
    background-color: #bcc5cd;
}

input[type="radio"]:checked {
    background: url("https://cdn-icons-png.flaticon.com/512/33/33281.png");
    background-size: cover;
    filter: invert(1);
    background-color: #d48300;
}

input[type="radio"]:focus {
    outline-color: transparent;
}

.cm-membership-service .form-group label {
    font-size: 1.25rem;
}

.cm-sign-box {
    padding: 1.5rem;
    border: 1px dashed;
    touch-action: none;
    min-height: 320px;

    img {
        height: 100%;
        max-height: 45vh;
        object-fit: contain;
    }

    @media (max-width: 767px) {
        min-height: 250px;

        img {
            max-height: 35vh;
        }
    }
}

.cm-sign-box-edit {
    padding: 1.5rem;
    border: 1px dashed;
    touch-action: none;

    @media (max-width: 767px), (hover: none) and (pointer: coarse) {
        max-height: 285px;

        &.view {
            canvas {
                width: 550px !important;
            }
        }        
    }
}

.cm-promoHighlight {
    border: 2px solid red !important;
}

.cm-promo-color {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
    font-weight: 900;
    font-size: 15px;
    text-decoration: underline;
}

.cm-plusminus-btnblock {
    color: var(--default-icon-primary, $defaultIconPrimary);
}

.cm-plusminus-btnblock .btn.btn-icon {
    border: 2px solid #B5B5C3;
    border-radius: 100%;
}

.cm-widget-img-block {
    /* min-width: 160px;
    min-height: 160px; */
    width: 100%;
    max-width: 160px;
    max-height: 160px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cm-widget-img-block img {
    width: 100%;
    /* min-width: 160px;
    min-height: 160px; */
    max-height: 160px;
    max-width: 160px;
    object-fit: cover;
    aspect-ratio: 1 / 1;
}

.symbol.symbol-55>img {
    min-height: 55px;
    aspect-ratio: 1 / 1;
}

.cm-text-primary {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.cm-text-danger {
    color: #B8241D !important;
}

.cm-border-bottom {
    border-bottom: 1px solid var(--default-icon-primary, $defaultIconPrimary) !important;
}

.h-70 {
    height: 70%;
}

.bottom-btns {
    position: absolute;
    bottom: 0;
    right: 15px;
}

.cm-user-select-none {
    user-select: none;
}

// .cm-flex-1 {
//     flex: 1 1 0 !important;
// }

.cm-content-section {
    height: 100vh;
    padding: 0 20px;
}

.cm-flex-md-0 {
    flex: 0 0 0 !important;
}

#AcceptUIContainer {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 90% !important;
    max-width: 360px;
    margin: 5% auto !important;
}

.cm-btn-sticy-wrapper {
    position: sticky;
    bottom: 0;
    background-color: #f7f7f7;
    padding: 10px 0;
}

.flex-0 {
    flex-grow: 0 !important;
}

@media (max-width: 767px) {
    .cm-flex-0 {
        flex: 0 0 0 !important;
    }

    .cm-widget-img-block {
        max-width: 120px;
        max-height: 120px;
    }
}

.cm-service-categories-img {
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    max-height: 225px;
}

.cm-chip-primary {
    display: inline-block;
    padding: 0.7em 1em 0.5em;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 0.25rem;
    background-color: var(--default-icon-primary, $defaultIconPrimary);
    color: #ffffff;
}

.cm-back-btn,
.btn-clean {
    color: var(--default-icon-primary, $defaultIconPrimary) !important;

    i {
        // color: var(--default-icon-primary, $defaultIconPrimary) !important;
    }

    svg {
        fill: gray;
    }

    &:hover {
        i, svg {
            color: var(--default-icon-primary, $defaultIconPrimary) !important;
            fill: var(--default-icon-primary, $defaultIconPrimary) !important;
        }
    }
}

.btn-clean-green {
    border: 1px solid $defaultProgressPrimaryColor !important;
    color: $defaultProgressPrimaryColor !important;

    i {
        color: $defaultProgressPrimaryColor !important;
    }

    &:hover {
        i {
            color: $defaultProgressPrimaryColor !important;
        }
    }
}

.text-hover-primary {
    &:hover {
        color: var(--default-icon-primary, $defaultIconPrimary) !important;
    }
}

.badge-primary {
    background-color: var(--default-icon-primary, $defaultIconPrimary);
}

.form-control {
    &:focus {
        border-color: var(--default-icon-primary, $defaultIconPrimary);
    }
}

.cm-btn-outline {
    background-color: transparent !important;
    border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.cm-btn-hidden {
    visibility: hidden;
}

.rm-more-button,
.rm-less-button {
    color: #FFFFFF !important;
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
    border: none !important;
    border-radius: 2px !important;
    // position: absolute;
    // bottom: 0;
    // right: 28px;
    margin-top: 5px;
}

.rm-typography {
    margin-bottom: 0;
}

input[type="radio"].cm-radio-btn,
input[type="checkbox"].cm-radio-btn {
    background-color: transparent;
    border: 1px solid #bcc5cd;
    border-radius: 50%;
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    appearance: none;
}

input[type="radio"].cm-radio-btn:checked,
input[type="checkbox"].cm-radio-btn:checked {
    background-image: url(https://cdn-icons-png.flaticon.com/512/33/33281.png);
    background-color: #964cff;
    border-color: #964cff;
    filter: invert(1);
}

input[type="checkbox"].cm-check-box {
    background-color: transparent;
    border: 1px solid #bcc5cd;
    border-radius: 2px;
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    appearance: none;
}

input[type="checkbox"].cm-check-box:checked {
    background-image: url(https://cdn-icons-png.flaticon.com/512/33/33281.png);
    background-color: #964cff;
    border-color: #964cff;
    filter: invert(1);
}

.selectedCheck {
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.underline {
    text-decoration: underline;
}

.medical-form {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin-left: auto;
    margin-right: 8px;
    vertical-align: middle;
}

.medical-form-scroll {
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-w-60 {
    max-width: 60% !important;
}

.cm-bg-green {
    background-color: rgb(147, 192, 31);
}

.cm-widget-listing {
    table.cm-table-striped {
        thead {
            th {
                border: 0;
            }
        }

        tbody {
            tr {
                background-color: transparent;
                td {
                    border: 0;
                }
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.letter-spacing-sm {
    letter-spacing: 0.5px;
}

.p-l-3 {
    padding-left: 3px;
}

#my-widget {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}

.h-100vh {
    height: 100vh;
}


.cm-video-consultation-wrapper {
    background: #121212;
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .cm-localParticipants {
        width: 20rem;
        overflow: hidden;
        padding: 1rem;
        position: absolute;
        top: 3rem;
        left: 3rem;
        z-index: 9;

        .cm-lp-wrapper {
            height: 100%;

            .cm-video-wrapper {
                height: 100%;

                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    object-fit: cover;
                    aspect-ratio: 16 / 12;
                }
            }

            h5 {
                position: absolute;
                left: 2rem;
                bottom: 2rem;
                margin: 0;
                color: #fff;
                text-shadow: 0 0 10px black;
            }
        }
    }

    .cm-remoteParticipants {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 12px;
        background-color: #000000;
        flex: 1 1 0;
        width: 100%;

        .cm-video-wrapper {
            border-radius: 12px;
            padding: 1.5rem;
            height: 100%;
            max-height: calc(88vh - 3rem);
            object-fit: cover;

            video, img {
                width: 100%;
                height: 100%;
                aspect-ratio: 9 / 12;
                object-fit: cover;
                border-radius: 12px;
            }
        }

        .cm-lp-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 1 0;

            h5 {
                position: absolute;
                left: 1rem;
                bottom: 1rem;
                color: #ffffff;
                margin-bottom: 0;
                text-shadow: 0 0 10px black;
            }
        }

        .cm-hcp-profile-bg{
            .cm-hcp-profile{
                width: 100px;
            }
        }

        .cm-progress-bar, .cm-progress-bar-loading {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 70px;
        }

        .cm-progress-bar-loaded {
            width:100px;
            height:6px; 
            background:#999;
            border-radius:5px;
            margin: 0 2px;
            margin-top: 20;
        }
    }

    .cm-camera-pause {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        flex: 1 1 0;
        color: rgba(255, 255, 255, 0.75);
    }

    .icon-image {
        height: 50px;
        width: 50px;
        margin: 0 auto;
    }

    .cm-note {
        color: rgba(255, 255, 255, 0.75);
        text-align: center;
        padding: 10px;
        margin-bottom: 0;
    }

    .cm-action-wrapper {
        background-color: #000000;
        margin-top: 1.5rem;
        padding: 1rem;
        width: 100%;
        justify-content: center;
        border-radius: 12px;
    }

    .timer-main-view-style {
        .timer-img-view {
            img {
                width: 50px;
                max-height: 50px;
                aspect-ratio: 1 / 1;
            }
        }

        .timer-detail-view {
            .timer-time {
                color: white;
                font-size: 30px;
            }
            .timer-desc-text {
                color: #ffffff;
            }
        }

        &.bg-success {
            background-color: rgb(147, 191, 31) !important;
        }
    
        &.bg-primary {
            background-color: #2B4168 !important;
        }
        
        &.bg-danger {
            background-color: #cc0000 !important;
        }
    }

    @media (min-width: 1921px) {
        .cm-localParticipants{
            width: 24rem;
        }

        .cm-remoteParticipants {    
            .cm-video-wrapper {
                max-height: 90vh;
            }
        }
    }

    @media (max-width: 991px) {
        .cm-localParticipants{
            width: 16rem;
        }
        .cm-remoteParticipants {    
            .cm-video-wrapper {
                max-height: calc(85vh - 3rem);
            }
        }
    }

    @media (max-width: 767px) {
        .cm-localParticipants{
            width: 11rem;
            top: calc(100vh - 24rem);
            right: 1.5rem;
            left: auto;

            .cm-lp-wrapper {    
                .cm-video-wrapper {    
                    video {
                        aspect-ratio: 9 / 12;
                    }
                }
            }
        }

        .cm-remoteParticipants {   

            padding-top: 20px;
            justify-content: flex-start; 

            .cm-video-wrapper {
                max-height: unset;
                padding: 0;
            }

            .cm-hcp-profile-bg{
                width: 60px;
                
                .cm-hcp-profile{
                    width: 40px;
                }
            }
        }
    }
}

.pb-13 {
    padding-bottom: 13px !important;
}

.mt-14 {
    margin-top: 14px !important;
}

.bg-color-brown{
    background-color: #6f3b2a;
    color: #fff;
}
.gap-10 {
    gap: 10px;
}

.txt-green{
color: #47d747;
}

.p-l-0{
    padding-left: 0
}

.profile-lbl-position {
    position: relative;
    bottom: 20px;
}

.upcoming-badge-color {
    color: #ffffff !important;
    background-color: #FFA800 !important;
}
txt-blue {
    color: #00B4E5;
}

.ul-w-33 {
    width: 33% !important;
}

.cm-mesure-unit {
    position: absolute;
    right: 1rem;
    top: 2.8rem;
    margin: 0;
    text-transform: none;
    
    &::after {
        margin-left: 0.5rem/*rtl:ignore*/;
        font-family: Ki;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        content: "";
    }
}

.cm-up-res-wrapper .cm-widget-img-block {
    max-height: 60px;
    max-width: 60px;
}

@media (max-width: 576px) {
    .cm-up-res-wrapper {
        display: none !important;
    }

    .cm-widget-img-block img {
        max-height: 100px;
        max-width: 100px;
        object-fit: cover;
        aspect-ratio: 1 / 1;
    }
}

.nav-btn {
    display: flex;
    border-radius: 4px;
    padding: 10px 24px;
    gap: 10px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    align-items: center;
}

.nav-btn.btn-primary {
    background-color: var(--default-icon-primary, $defaultIconPrimary) !important;;
}

.nav-btn.btn-clear {
    background-color: transparent;
}


@media (max-width: 768px) {
    .nav-btn {
        justify-content: center;
        align-items: center;
        padding: 10px 12px;
    }
    .dashboard-card {
        margin-bottom: 0px;
    }
}
