.mainContainer {
  padding: 10px 100px;
  flex:1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  gap: 20px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.container {
  padding: 12px 20px;
  display: none;
}

.leftInnerContainer {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left {
  width: 50%;
  max-width: 565px;
  flex-shrink: 0;
}

.rightInnerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 700px;
  flex-grow: 1;
  gap: 8px;
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 #00000014;
  padding-top: 20px;
}

.rightInnerTopContainer {
  padding: 16px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 0;
}

.right {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  padding: 16px 20px;
  box-shadow: 0 -4px 16px 0 #00000014;
  border-radius: 0 0 16px 16px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  overflow: auto;
  gap: 24px;
  overflow-y: auto;
}

.header {
  font-size: 54px;
  font-weight: 900;
  line-height: 58.32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #252527;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.subTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #717171;
}

@media (max-width: 1200px) {
  .mainContainer {
    padding: 10px 50px;
  }
}

@media (max-width: 768px) {
  .left {
    display: none;
  }
  .mainContainer {
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
  .rightInnerContainer {
    max-width: 100%;
    max-height: 100%;
    box-shadow: none;
    padding-top: 0;
  }
  .right {
    height: calc(100% - 116px);
  }
  .container {
    display: flex;
  }
}
