/* style.module.css */
.mainContainer {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.bannerContainer {
  width: 100%;
  background-size: cover;
  position: relative;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.mainContentContainer {
  display: flex;
  flex-grow: 1;
  overflow: hidden; /* Prevent mainContentContainer from scrolling */
  transition: all 0.3s ease;
}

.nav {
  background-color: #fff;
  box-shadow: 0px 4px 24px 0px #00000005;
  height: 64px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #f7f7f7;
}

.navBackButton {
  width: 40px;
  padding: 6px 0;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  margin-left: -15px;
}

.whiteLabelIcon {
  width: 47px;
  height: 47px;
  position: relative;
  margin-left: 50px;
  cursor: pointer;
}

.whiteLabelIcon img {
  width: 47px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  object-fit: fill;
}

.navLinksContainer {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
  flex: 2;
}

.navSwitchContainer {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0px;
  margin: 0;
  flex: 1;
  padding-left: 10px;
  color: var(--default-icon-primary);
}

.mNavLinksContainer {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  flex: 1;
  margin-top: 24px;
}

.navLink {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: center !important;
  cursor: pointer;
  transition: color 400ms ease-in-out;
}

.activeLink {
  color: #3F4254;
}

.inactiveLink {
  color: #B5B5C3;
}

.loginContainer {
  display: flex;
  margin-right: 50px;
}

.mobileHeaderButton {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.left {
  width: 411px;
  transition: all 0.3s ease;
  /* Ensure left container stays on left */
  z-index: 10;
  overflow: hidden;
  max-height: 100%;
  display: flex;
  justify-content: end;
  flex-shrink: 0;
}

.leftInnerContainer {
  height: 100% !important;
}

.right {
  flex-grow: 1; 
  overflow: hidden; /* Prevent right from scrolling */
  padding: 0;
  position: relative;
}

.rightInnerContainer {
  display: flex;
  flex-direction: column; /* Stack navigation and doubleContainer */
  max-width: 100%;
  flex-grow: 1;
  height: 100%;
}

.navigationContainer { /* Container for your navigation */
  height: auto; /* Example fixed height for navigation */
  padding: 0 20px;
  margin: 0 auto;
  max-width: 896px;
  width: 100%;
  min-width: 200px;
}

.doubleContainer { /* Your new container for top/bottom layout */
  display: flex;
  flex-direction: column; /* Stack top and bottom */
  flex-grow: 1; /* Take up the remaining space after navigation */
  overflow: hidden; /* Prevent doubleContainer from scrolling */
  /* margin: auto;
  max-width: 768px; */
}

.topContainer { /* Scrollable top section */
  overflow-y: none; /* Allow vertical scrolling */
  margin: 0 auto;
  flex-grow: 1;
  max-width: 896px;
  width: 100%;
  min-width: 200px;
}

.bottomContainer { /* Fixed-height bottom section */
  height: 60px; /* Example fixed height */
  display: flex;
  align-items: center; /* Center content vertically */
  padding: 20px;
  margin-bottom: 3px;
  margin: 0 auto;
  max-width: 936px;
  width: 100%;
  min-width: 200px;
  background-color: #fff;
  box-sizing: border-box;
}

.buttonContainer {
  padding: 12px 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ########################## BANNER STYLE #############################  */

.outerContainer {
  width: 100%;
  margin: 0px auto; /* Center the container */
}

.imageContainer {
  position: relative; /* To position the component on top */
  width: 100%;
  height: 167px; /* Default height for desktop */
  background-size: cover;
  border-radius: 8px; /* Border radius for desktop */
  padding: 20px; /* Padding for desktop */
}

.topComponent {
  position: absolute;
  top: 20px;
  right: 20px;
}

.mTopComponent {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
}

/* style.module.css */

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}

.header {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

/* Image Styles */
.detailsImageContainer {
  display: flex;
  width: 156px; /* Fixed height for image container */
  height: 116px;
  border: 2px solid #0000000A;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0000000A;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 25px;
  flex-shrink: 0;
}

.detailsImageContainer img {
  transition: all 0.3s ease-in-out;
  width: 100%;
  object-fit: fill;
}

.mDetailsImageContainer {
  display: none;
  height: 72px; /* Fixed height for image container */
  width: 96px;
  border: 1px solid #0000000A;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0000000A;
  margin: 10px 0 10px;
  margin-left: -4px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mDetailsImageContainer img {
  width: 100%; /* Image size for desktop */
  transition: all 0.3s ease-in-out;
  object-fit: fill;
}

.mSideImageContainer {
  flex-shrink: 0;
  height: 54px; /* Fixed height for image container */
  width: 54px;
}

.mSideImageContainer img {
  width: 100%; /* Image size for desktop */
  border-radius: 8px;
  object-fit: fill;
}

/* List Styles */
.listContainer {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  overflow: auto;
  width: 331px;
  gap: 20px;
  overflow-y: auto;
  height: calc(100% - 50px);
}

.listItem {
  display: flex;
  align-items:flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #F7F7F7;
  border-radius: 12px;
  align-items: center;
  text-decoration: none; /* Remove underline */
  gap: 10px;
}

.listIcon {
  margin-right: 0px;
  padding: 16px;
  /* Your left icon styles here */
}

.listText {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #252527;
  padding: 16px 0;
  padding-right: 16px;
}

.listEllipsis {
  margin-left: 10px;
  /* Your ellipsis icon styles here */
}

.copyButton {
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 16px !important;
  cursor: pointer;
}

/* ################## Mobile styles ###################### */

.businessMobileView {
  display: none;
  flex-direction: column;
  gap: 4px;
}

.mHeadingWithButton {
  display: none;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.visible {
  display: block;
}

.invisible {
  display: none;
}

.detailsTray {
  width: 100%; /* Full width of the parent */
  overflow: auto;
  border-radius: 0 0 8px 8px;
  transition: opacity .5s ease-in-out;
  opacity: 0;
  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  -webkit-overflow-scrolling: touch; 
  /* Hide scrollbar for Firefox */
  overflow: -moz-scrollbars-none;
  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for all browsers */
  scrollbar-width: none; 
  /* This is the most important line! */
  overflow-y: auto; /* This allows scrolling */
}

.hide-scrollbar {
  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  -webkit-overflow-scrolling: touch; 
  /* Hide scrollbar for Firefox */
  overflow: -moz-scrollbars-none;
  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for all browsers */
  scrollbar-width: none; 
  /* This is the most important line! */
  overflow-y: auto; /* This allows scrolling */
}


.detailsTray.activeTray {
  height: auto;
  opacity: 1;
}

.detailsTray.inactiveTray {
  height: 0;
  opacity: .7;
}


.bgtemp {
  background-color: #fff;
  width: 100%;
  height: fit-content;
  outline: 20px solid #fff;
  border-radius: 0 0 8px 8px;
  border: 0.5px solid #e6e6e6;
  border-top: none;
}

.description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #717171;
}

/* ################## SIDE SHEET CSS ################### */

.sheetContainer {
  position: relative;
  width: 100%;
  height: 0;
  display: none;
}

.menuButtonContainer {
  height: 48px;
  width: calc(100vw - 40px);
  display: none;
  flex: 1;
  justify-content: space-between;
}

.menuButton {
  /* Style your menu button */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.icon {
  color: #252527;
  transition: all 300ms ease-in-out;
}

.sideSheet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.089);
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: translateX(-100%); /* Start off-screen */
  backdrop-filter: blur(10px);
  opacity: 0;
  z-index: 1;
}

.sideSheetOpen {
  transform: translateX(0); /* Slide in */
  opacity: 1;
}

.sideSheetModal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: calc(100% - 70px); /* 100px less than side sheet */
  height: 100%;
  box-shadow: 0 0 10px  #0002;
  z-index: 9999999 !important;
}

.sideSheetModalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  overflow-x: hidden;
}

.closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
}

.linkBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #252527;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

/* ################## SIDE SHEET CSS ################### */

/* Responsive styles for tab */
@media (max-width: 1000px) {
  .imageContainer {
    height: 108px; /* Reduced height for mobile */
    padding: 0; /* Remove padding for mobile */
  }

  .topComponent {
    top: 10px; /* Adjust top position for mobile */
    right: 10px; /* Adjust left position for mobile */
  }

  .header {
    padding-left: 0px;
  }

  .left {
    width: 361px;
  }
}
/* Responsive styles for mobile */
@media (max-width: 768px) {
  .nav {
    display: none;
  }
  .topComponent {
    display: none;
  }
  .mTopComponent {
    display: block;
  }
  
  .imageContainer {
    height: 108px; /* Reduced height for mobile */
    padding: 0; /* Remove padding for mobile */
  }

  .topComponent {
    top: 10px; /* Adjust top position for mobile */
    right: 10px; /* Adjust left position for mobile */
  }

  .mainContentContainer {
    flex-direction: column; /* Stack content vertically on mobile */
  }

  .left {
    width: 100%;
    max-width: none;
    order: -1; /* Move left to the top */
    flex: 0 0 auto; 
    /* Set a fixed height for the left section on mobile */
    max-height: fit-content; 
    padding: 0 20px;
    padding-bottom: 5px;
    overflow: unset;
  }

  .right {
    flex-grow: 1;
  }

  .listContainer {
    display: none;
  }

  .businessMobileView {
    display: flex;
    /* position: relative; */
  }
  .mHeadingWithButton {
    display: flex;
  }

  .headerContainer>h3 {
    text-overflow: ellipsis;
  }

  .copyButton > span {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header {
    padding-left: 0px;
  }

  .buttonWrapper {
    margin: 0;
  }

  .copyButton {
    margin-left: 0px;
    margin-top: 0px !important;
    display: flex;
    justify-content: right;
  }

  .buttonContainer {
    padding: 12px 0px;
  }

  .detailsImageContainer {
    display: none;
  }

  .mDetailsImageContainer {
    display: flex;
  }

  .sheetContainer {
    display: block;
  }

  .navLink {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-align: left !important;
    cursor: pointer;
    transition: color 400ms ease-in-out;
    height: 48px;
    padding: 0px 12px;
    gap: 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }

  .activeLink {
    background-color: #f7f7f7;
  }

  .menuButtonContainer {
    display: flex;
  }

  .activeLink {
    color: #252527;
  }
  
  .inactiveLink {
    color: #252527;
  }

  .loginContainer {
    display: flex;
    margin-right: 0px;
    flex-direction: column;
    gap: 4px;
  }

  .leftInnerContainer {
    width: 100% !important;
  }

  .outerContainer {
    padding: 0 20px;
  }
  
}

@media (max-width: 480px) {
  .imageContainer {
    height: 65px; /* Reduced height for mobile */
    padding: 0; /* Remove padding for mobile */
  }

  .topComponent {
    top: 10px; /* Adjust top position for mobile */
    right: 10px; /* Adjust left position for mobile */
  }

  .mainContentContainer {
    flex-direction: column; /* Stack content vertically on mobile */
  }

  /* .buttonContainer {
    flex-direction: column;
  } */

  .buttonWrapper {
    width: auto;
  }
  .fullWidth {
    width: 100%;
  }

  .left {
    width: 100%;
    max-width: none;
    order: -1; /* Move left to the top */
    flex: 0 0 auto;
    overflow: unset;
    padding-bottom: 0;
  }

  .right {
    flex-grow: 1;
  }

  .header {
    padding-left: 0px;
  }
}

@keyframes shrink {
  from {
    height: auto;
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

@keyframes grow {
  from {
    height: 0;
    opacity: 1;
  }
  to {
    height: auto; /* Let the child grow to its natural height */
    opacity: 1;
  }
}
