.custom-select-container {
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.custom-select-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: 8px;
  background-color: #F7F7F7;
  cursor: pointer;
  height: 48px;
  margin-top: 16px;
}

.custom-select-input input {
  width: 100%;
  border: none;
  outline: none;
  flex-grow: 1;
  padding-right: 8px;
  background-color: transparent;
}

.dropdown-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}

.dropdown-list {
  display: flex;
  gap: 8px;
  width: 100%;
  height: calc(100% - 48px);
  background-color: white;
  flex-grow: 1;
  overflow-y: auto;
  flex-wrap: wrap;
}

.gridContainer {
  display: grid;
  gap: 8px;
  padding: 20px 5px;
  width: 100%;
  height: calc(100% - 48px);
  flex-grow: 1;
  overflow-y: auto;
}

.gridItem {
  background-color: white;
  padding: 8px 16px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  text-align: center;
  font-size: 1.2rem;
  transition: transform 0.2s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: fit-content;
}

.gridItem > span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #252527;
}

.gridItem:hover {
  transform: scale(1.02);
}

.gridItem.selected {
  transform: scale(1.04);
  border-color: var(--default-icon-primary);
}

.dropdown-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.dropdown-details {
  display: flex;
  flex-direction: column;
}

.business-name {
  font-weight: bold;
}

.business-state {
  color: #666;
}

.no-results {
  padding: 8px;
  text-align: center;
  color: #666;
}

/* For large screens: 3 columns */
@media (min-width: 1141px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* For medium screens: 2 columns */
@media (min-width: 858px) and (max-width: 1140px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}

/* For small screens: 1 column */
@media (max-width: 767px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 480px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 355px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}
