.detailsContainer {
  margin-top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.profileContainer {
    position: fixed;
    width: 80% !important;
  }
  
  .profileImgContainer {
    margin-bottom: 140px;
  }
  
  .profileImg {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border: 5px solid white;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-80%, -60%);
  }
  
  .profileDetails {
    position: relative;
    transform: translate(-20%, 0);
    z-index: 1;
    font-size: 24px;
  }

  .businessDetails{
    overflow-y: auto;
    max-height: calc(100vh - 380px);

  }
  
  .businessDescription,
  .businessAddress,
  .businessPhone {
    font-weight: 500;
    color: #808080;
    font-size: 14px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px !important;
  }

  .businessDescriptionSvg,
  .businessAddressSvg,
  .businessPhoneSvg {
    margin-right: 10px;
    color: #808080;
  }

  .svgIcon {
    margin-right: 10px;
  }
  
  .buttonContainer {
    max-width: 350px;
    font-weight: medium;
    color: #808080;
    font-size: 12px;
  }
  
  .copyButton {
    font-weight: 500;
    font-size: 12px;
  }

  .detailsToggle {
    background: none;
    border: none;
    cursor: pointer;
    color: #252527;
  }
  
  .mobileDetails {
    display: none;
    margin-top: 10px;
  }
  
  .mobileDetails.show {
    display: block;
  }

  .detailsToggle {
    background: none;
    border: none;
    cursor: pointer;
    color: #252527;
  }
  
  .mobileDetails {
    display: none;
    margin-top: 10px;
  }
  
  .mobileDetails.show {
    display: block;
  }

  .detailsToggle {
    background: none;
    border: none;
    cursor: pointer;
    color: #252527;
  }
  
  .mobileDetails {
    display: none;
    margin-top: 10px;
  }
  
  .mobileDetails.show {
    display: block;
  }

  @media (max-width: 1500px) {
    .profileImg {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border: 5px solid white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-80%, -60%);
    }

    .profileImgContainer {
        margin-bottom: 100px;
    }

    .profileDetails {
        position: relative;
        transform: translate(-10%, 0);
        z-index: 1;
        font-size: 18px;
    }

    .businessDescription,
    .businessAddress,
    .businessPhone {
        font-size: 18px;
    }

    .buttonContainer {
        max-width: 310px;
        font-weight: medium;
        color: #808080;
        font-size: 12px;
    }

    .copyButton {
      font-size: 12px;
    }
  }
  
  @media (max-width: 1000px) {
    .profileDetails {
      font-size: 14px;
    }


    .profileImgContainer {
        margin-bottom: 100px;
    }

    .businessDescription,
    .businessAddress,
    .businessPhone {
        font-size: 14px;
    }

    .buttonContainer {
        max-width: 245px;
        font-weight: medium;
        color: #808080;
        font-size: 10px;
    }


    .copyButton {
        font-size: 10px;
    }
  }
  
  @media (max-width: 770px) {
    
    .detailsContainer {
      max-height: 20%;
      margin-bottom: 10%;
      align-items: start !important;
      justify-content: left !important;
      padding-left: 20px !important;
    }

    .profileContainer {
      align-items: start !important;
      justify-content: left !important;
    }

    .profileImg {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 5px solid white;
        position: relative;
        transform: translate(-10%, -60%);
    }

    .profileImgContainer {
      display: relative;
        width: 1px !important;
        height: 1px !important;
        margin-bottom: 50px;
    }

    .profileDetails {
      font-size: 10px;
      transform: none;

    }
    
    h3 {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 0 !important;
    }

    .businessDescription,
    .businessAddress,
    .businessPhone {
        font-size: 12px;
        margin-bottom: 8px !important;
    }


    .svgIcon {
      height: 15px;
      width: 15px;
      margin-right: 5px;
    }

    .buttonContainer {
        max-width: 350px;
        font-weight: medium;
        color: #808080;
        font-size: 12px;
    }


    .detailsToggle {
      position: relative;
      top: 0;
      right: 0;
      padding: 5px;
      font-size: 14px;
    }
  
    .mobileDetails {
      display: block;
    }

    .copyButton {
        font-size: 12px;
        margin-bottom: 10px !important;
    }
  }
  
  @media (max-width: 450px) {
    .detailsContainer {
      max-height: none;
      margin-bottom: 0;
      align-items: start !important;
      justify-content: left !important;
      padding-left: 20px !important;
    }

    .profileContainer {
      align-items: start !important;
      justify-content: left !important;
    }

    h3 {
      font-size: 14px;
    }

    .profileImg {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border: 5px solid white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-15%, -70%);
    }

    .profileImgContainer {
        margin-bottom: 20px;
    }

    .profileDetails {
      font-size: 10px;
      transform: none;
    }

    .businessDescription,
    .businessAddress,
    .businessPhone {
        font-size: 10px;
    }

    .buttonContainer {
        max-width: 250px;
        font-weight: medium;
        color: #808080;
        font-size: 10px;
    }

    .copyButton {
        font-size: 10px;
        margin-bottom: 0;
    }
  }
