.container {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  
  .container2 {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
  }
  
  .leadingIconContainer {
    margin-right: 15px;
    align-items: center;
    display: flex;
  }
  
  .listContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    gap: 2px;
    justify-content: center;
  }
  
  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-weight: 600;
    color: #252527;
    font-size: 14px;
  }
  
  .subheading {
    color: #717171;
    font-weight: 500;
    font-size: 14px;
    text-wrap: wrap;
  }
  
  .iconDiv {
    align-items: center;
    display: flex;
    gap: 5px;
  }
  
  .labelContainer {
    width: 94px;
    min-height: 24px;
    background-color: #fff1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a95f03;
    border-radius: 4px;
    margin: 8px;
    font-size: 12px;
    font-weight: 600;
  }

  @media (min-width: 780px) and (max-width: 1200px) {
    .labelContainer {
      width: 50px;
    }
  }
