.splash_screen {
  position: absolute;
  z-index: 999999 !important;
  width: 100%;
  height: 100%;
  background-color: #2b416822;
}

.splash_screen i {
  font-size: 32px;
  color: #2b4168;
}
