.primaryBtn {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: center !important;
  min-width: 52px !important;
  height: 48px !important;
  padding: 20px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: var(--default-icon-primary) !important;
  cursor: pointer !important;
  transition: all 0.1s ease-in-out !important;
}

.primaryBtn:active {
  opacity: .8 !important;
}

.disabledBtn{
  color: #ACACAC !important;
  background-color: #E8E8E8 !important;
  cursor: not-allowed !important;
}

.btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: center !important;
  min-width: 52px !important;
  height: 48px !important;
  padding: 20px !important;
  display: flex !important;
  gap: 4px !important;
  align-items: center !important;
  justify-content: center !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  cursor: pointer !important;
  transition: all 0.1s ease-in-out !important;
  border-radius: 48px !important;
}

.btn:active {
  opacity: .8 !important;
}

.primary {
  background-color: var(--default-icon-primary) !important;
  color: #fff !important;
}

.secondary {
  background-color: #F7F7F7 !important;
  color: #3F4254 !important;
}

.secondaryDanger {
  background-color: #F7F7F7 !important;
  color: #B8241D !important;
}
